import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { BodyRenderer, TabsNavigation } from "components";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import useFilterLocale from "hooks/useFilterLocale";
import React, { useMemo } from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import ImageBanner from "../components/ImageBanner";

const Image = styled(Img)`
  positon: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  object-fit: cover;
  object-position: top;
`;

const ImageContainer = styled.div`
  background: ${props => `url(${props.background}) no-repeat top left`};
  width: 100%;
  height: 500px;
`;

const useStyles = makeStyles(theme => ({
  mainContainer: {
    width: "100%"
  },
  pageheading: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    textAlign: "center",
    marginTop: 50,
    letterSpacing: 5
  },
  imageContainer: {
    position: "relative",
    width: "100%",
    display: "flex",

    marginBottom: "20px"
  },
  mainContainer: {
    padding: 20
  },
  heading: {
    // fontFamily: 'Libre Baskerville',
    // fontWeight: 700,
    // textTransform: 'capitalize',
    // marginBottom: 20,
    // marginTop: 30,
    // fontSize: 50,
    margin: "40px 0px",

    [theme.breakpoints.down("sm")]: {
      fontSize: "40px"
    }
  },
  content: {
    "& a": {
      color: theme.palette.primary.main,
      textDecoration: "none"
    },
    "& div": {
      margin: "10px 0px"
    }
  },
  contentContainer: {
    margin: "0 auto"
  },

  imageBanner: {}
}));

function Service(props) {
  const {
    pageContext: { locale, node_locale },
    data: { allServices, service }
  } = props;

  const classes = useStyles();
  const links = useMemo(
    () =>
      allServices.edges.map(edge => ({
        ...edge.node,
        title: edge.node.serviceName
      })),
    [allServices.edges]
  );
  const filteredLinks = useFilterLocale(links);

  return (
    <Container maxWidth="xl">
      <Helmet
        description={service.metaDescription}
        htmlAttributes={{ lang: node_locale || locale }}
        title={service.metaTitle}
      >
        <meta name="description" content={service.metaDescription}></meta>
        <meta name="title" content={service.metaTitle}></meta>
      </Helmet>

      <Grid container className={classes.mainContainer} justify="space-around">
        <Grid container direction="column">
          <Grid item className={classes.contentContainer} sm={12} xs={12}>
            <TabsNavigation links={filteredLinks} />
            <Box>
              <Typography className={classes.heading} variant="h1">
                {service.serviceName}
              </Typography>
            </Box>

            {/* <ImageContainer
              background={props.background}
              className={classes.imageContainer}
            >
              <Image
                alt={service.photo.title}
                fluid={service.photo.fluid}
                className={classes.image}
              />
            </ImageContainer> */}

            <ImageBanner bannerImage={service.photo.fluid.src}></ImageBanner>

            <div className={classes.content}>
              <BodyRenderer json={service.description.json} />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Service;

export const offerQuery = graphql`
  query($slug: String!, $locale: String!) {
    allServices: allContentfulService(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          id
          slug
          node_locale
          serviceName
        }
      }
    }
    service: contentfulService(slug: { eq: $slug }) {
      id
      metaDescription
      metaTitle
      slug
      node_locale
      serviceName
      description {
        json
      }
      photo {
        fluid(maxWidth: 1920, maxHeight: 467, quality: 90) {
          ...GatsbyContentfulFluid_withWebp
        }
        title
      }
    }
  }
`;
