import Img from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'

const ImageBannerContainer = styled.div`
  position: relative;
  background: ${props => `url(${props.background}) no-repeat top left`};
  width: 100%;
  height: 60vh;
  background-size: cover;
  background-position: top;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
`

export default function ImageBanner (props) {
  return (
    <ImageBannerContainer background={props.bannerImage} />
  )
}
